

























































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import { Data } from '@/utils/types/WidgetData';
import SliderArrowComponent from '@/components/SliderArrowComponent.vue';
import StatLoggerActions from '@/utils/enums/StatLoggerActions';
import StatLoggerCategories from '@/utils/enums/StatLoggerCategories';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component({
  components: {
    SliderArrowComponent,
    Splide,
    SplideSlide,
  },
})
export default class SponsorListComponent extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  protected baseStoreName = 'SponsorListWidgetStore';

  @Prop({ required: false, default: null })
  private readonly title!: string;

  @Prop({ required: false, default: null })
  private readonly subtitle!: string;

  @Prop({ required: false, default: () => [] })
  private readonly data!: Data[];

  private isSlide = false;

  private options = {
    type: 'loop',
    fixedWidth: 96,
    fixedHeight: 96,
    gap: 46,
    padding: { left: '3rem', right: '3rem' },
    trimSpace: 'move',
    focus: 'center',
    loop: true,
    rewind: true,
    pagination: false,
    cover: true,
    perMove: 1,
    breakpoints: {
      1439: {
        padding: { left: '3rem', right: '3rem' },
      },
      767: {
        padding: { left: '1rem', right: '1rem' },
        gap: 56,
      },
      450: {
        gap: 46,
      },
    },
  }

  created(): void {
    this.setDataConfig();
  }

  @Watch('windowWidth', { deep: true })
  @Watch('data')
  updateSlideUI(): void {
    this.$nextTick(() => {
      if (this.$el && this.data && this.data.length) {
        const size = 96;
        // eslint-disable-next-line no-nested-ternary
        const padding = this.isMobile
          ? 24
          : this.isTablet
            ? 40 : 56;
        const items = this.data.length;
        const length = ((items - 1) * size) + ((items - 1) * 56) + (padding * 2);
        const slides = this.$el.getElementsByClassName('slides');
        if (slides && slides.length > 0) {
          this.isSlide = length >= slides[0].getBoundingClientRect().width;
        }
      }
    });
  }

  private onClick(component: Data, payload: Data): void {
    if (this.data[payload.index as number].url) {
      if (this.data[payload.index as number].external) {
        window.open(this.data[payload.index as number].url as string, '_blank');
      } else {
        this.$router.push(this.data[payload.index as number].url as string);
      }
      this.$logger.logMatomoStats(
        this.authUser,
        this.community.code || '',
        '',
        StatLoggerActions.CLICK,
        this.data[payload.index as number].url as string,
        -1,
        '',
        StatLoggerCategories.SPONSOR,
        this.$i18n.locale,
      );
    }
  }

  private nextClick(): void {
    const arrowNextEl = this.$el
      .querySelector(`.sponsor-list-${this.widget.uid} .splide__arrow--next`) as HTMLElement;
    if (arrowNextEl) {
      arrowNextEl.click();
    }
  }

  private prevClick(): void {
    const arrowPrevEl = this.$el
      .querySelector(`.sponsor-list-${this.widget.uid} .splide__arrow--prev`) as HTMLElement;
    if (arrowPrevEl) {
      arrowPrevEl.click();
    }
  }
}
